import axios from "axios";

const state = {
  checkListData: null
}

const getters = {
  checkListData: (state) => state.checkListData,
}

const mutations = {
  checkListData(state, checkListData) {
    state.checkListData = checkListData;
  }
}

const actions = {

  //チェックリスト内容取得
  async loadChecklistContets({commit}) {
    let date = new Date()
    date = date.toJSON()
    date = date.split('T')

    await axios
      .post("/wp-json/pasti/v1/checklist/status", {
        date: date[0]
      })
      .then( response => {
        console.log("Success Load Check list content");
        commit('checkListData', response.data )
      })
      .catch(() => {
        console.log("Failed Load Check list content");
      });
  },

  //コメント追加
  addComment({getters,commit}, data) {
    
    let list = getters.checkListData
    let updateRow = list.find( ({title}) => title === data.title )

    if(updateRow){
      updateRow.comments.push({
        id: data.id,
        content: data.content
      })
    }else{
      updateRow = {
        title: data.title,
        status: null,
        comments: [{
          id: data.id,
          content: data.content
        }]
      }
    }
    list.push(updateRow)
    commit('checkListData',list)
  },

}

const CheckList = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

export default CheckList;
