import { createStore } from "vuex"
import Authorize from './Authorize.js'
import CheckList from './CheckList.js'
import Kennel from './Kennel.js'
import Animal from './Animal.js'
import AnimalLog from './AnimalLog.js'
import Staff from './Staff.js'
import Doctor from './Doctor.js'
import Mediate from './Mediate.js'

export default createStore({
  modules: {
    Authorize,
    CheckList,
    Kennel,
    Animal,
    AnimalLog,
    Staff,
    Doctor,
    Mediate
  }
})
