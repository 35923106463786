import axios from "axios";

// const baseUrl = "https://boiler.xsrv.jp/DEV/pasti";
// axios.defaults.baseURL = baseUrl;

const state = {
  staffList: [],
  error: null,
  processing: false,
  update: false,
}

const getters = {
  staffList: (state) => state.staffList,
  error: (state) => state.error,
  processing: (state) => state.processing,
  update: (state) => state.update,
}

const mutations = {
  staffList(state, staffList) {
    state.staffList = staffList;
  },
  error(state, error) {
    state.error = error;
  },
  processing(state, processing) {
    state.processing = processing;
  },
  update(state, update) {
    state.update = update;
  }
}

const actions = {
  loadStaffList({commit}) {
    try {
      axios
        .get("/wp-json/pasti/v1/staff")
        .then((response) => {
          console.log('Success load staff list')
          commit("staffList", response.data)
        })
    }
    catch (error) {
      const {
        status,
        statusText
      } = error.response;
      console.log(`Error! HTTP Status: ${status} ${statusText}`)
    }
  },
  attendance({commit}, data) {
    axios
    .post("/wp-json/pasti/v1/staff/status", {
      id: data.id,
      status: data.status
    })
    .then(() => {
      commit("update", true)
    })
  },
  addStaff({commit,dispatch}, name) {
    commit('processing', true)
    try {
      axios
        .post("/wp-json/pasti/v1/staff/add", {
          name: name
        })
        .then((response) => {
          if( response.data === false ){
            commit('error', true)
          }else{
            commit('update', true)
            console.log('Success add staff')

            dispatch("pushIntoStaffList", {
              id: response.data,
              name: name,
              status: false,
            })
  
            commit('processing', false)
          }
        })
    }
    catch (error) {
      const {
        status,
        statusText
      } = error.response;
      console.log(`Error! HTTP Status: ${status} ${statusText}`)
    }
  },
  pushIntoStaffList({getters,commit},data) {
    let newStaff = {
      id: data.id,
      name: data.name,
      status: false,
    }
    let newList = getters['staffList']
    newList.unshift(newStaff)
    commit('staffList', newList)
  }
}

const StaffInfo = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}

export default StaffInfo;
