import axios from "axios"
import router from "../router";
import speciesList from "@/assets/speciesList.js"

const state = {
  container: null,
  speciesList: [],
  processing: false,
}

const getters = {
  container: (state) => state.container,
  speciesList: (state) => state.speciesList,
  processing: (state) => state.processing,
}

const mutations = {
  container(state, container) {
    state.container = container;
  },
  speciesList(state, speciesList) {
    state.speciesList = speciesList;
  },
  processing(state, processing) {
    state.processing = processing;
  },
}

const actions = {
  add({commit}, data) {
    commit('processing', true)

    try {
      let fd = new FormData()
      fd.append("pic", data.uploadFile)
      fd.append("animaldata", JSON.stringify(data))
      axios
        .post("/wp-json/pasti/v1/animal", fd)
        .then((response) => {
          console.log(response)
          commit('processing', false)
          router.push('/kennel')
        })
    }
    catch (error) {
      const {
        status,
        statusText
      } = error.response;
      console.log(`Error! HTTP Status: ${status} ${statusText}`)
      commit('processing', false)
    }
  },
  update({commit}, data) {
    commit('processing', true)
    try {
      let fd = new FormData()
      fd.append("pic", data.uploadFile)
      fd.append("animaldata", JSON.stringify(data))
        axios
        .post(`/wp-json/pasti/v1/animal/${data.wp_post_id}/update`, fd)
        .then((response) => {
          console.log(response)
          commit('processing', false)
          router.push('/kennel')
        })
    }
    catch (error) {
      const {
        status,
        statusText
      } = error.response;
      console.log(`Error! HTTP Status: ${status} ${statusText}`)
      commit('processing', false)
    }
  },
  delete({commit}, id) {
    commit('processing', true)
    axios
      .post("/wp-json/pasti/v1/animal/delete", {
        id: id
      })
      .then((response) => {
        console.log(response)
        commit('processing', false)
        router.push('/kennel')
      })
      .catch((error) => {
        console.log(error);
      });
  },
  reset({commit}) {
    commit('container', null)
  },
  getInfo({commit}, id) {
    if(!id) return

    console.log("try get detail info")
    axios
    .get(`/wp-json/pasti/v1/animal/${id}`)
    .then((response) => {

      //誕生日
      let birthday = response.data.birthday;
      birthday = birthday.split("T")
      response.data.birthday = birthday[0]

      //入手日
      let obtainDate = response.data.ownership_date;
      obtainDate = obtainDate.split("T")
      response.data.ownership_date = obtainDate[0]
      commit('container', response.data)
    })
  },
  addLog(context, data) {
    axios
    .post(`/wp-json/pasti/v1/animal/${data.id}/log`, {
      genre: data.genre,
      str: data.str
    })
    .then((response) => {
      console.log(response)
    })
  },
  loadMatchSpecies({getters, commit}, str) {
    if(str.length){
      const species = speciesList[getters["genus"]]
      commit('speciesList', [])
      let tempList = []
      species.forEach((row) => {
        if( row.name.indexOf(str) !== -1 ){
          tempList.push(row)
        }
      })
      commit('speciesList', tempList)
    }
  },
  toggleOnSale({state}) {
    state.container.IsOnSale = state.container.IsOnSale === "1" ? "0" : "1"
    axios
      .post("/wp-json/pasti/v1/animal/status", {
        animal_id: state.container.animal_id,
        target: "IsOnSale",
        value: state.container.IsOnSale,
      })
      .then((response) => {
        console.log(response)
      })
      .catch((error) => {
        console.log(error);
      });
  },
  toggleIsBreedable({state}) {
    state.container.IsBreedable = state.container.IsBreedable === "1" ? "0" : "1"
    axios
      .post("/wp-json/pasti/v1/animal/status", {
        animal_id: state.container.animal_id,
        target: "IsBreedable",
        value: state.container.IsBreedable,
      })
      .then((response) => {
        console.log(response)
      })
      .catch((error) => {
        console.log(error);
      });
  },
  dead({state,commit}, data) {
    commit("processing", true)
    axios
    .post("/wp-json/pasti/v1/log/dead", {
      animal_id: state.container.animal_id,
      animal_type: state.container.animal_type,
      control_number: state.container.control_number,
      microchip_no: state.container.microchip_no,
      my_number: state.container.my_number ? state.container.my_number : "",
      veterinarian_id: state.container.veterinarian_id,
      veterinarian_name: state.container.veterinarian_name,
      breeder_member_id: state.container.breeder_member_id,
      breeder_member_company: state.container.breeder_member_company,
      breeder_member_name: state.container.breeder_member_name,
      death_datetime: data.datetime, //"2022-02-01 16:57",
      cause_of_death: data.cause,
      memo: data.memo
    })
    .then( response => {
      console.log(response)
      commit("processing", false)
      router.push("/kennel")
    })
    .catch((error) => {
      console.log(error);
    });
  }
}

const Animal = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

export default Animal;
