import axios from "axios";
import router from "../router";

//let baseUrl = "https://boiler.xsrv.jp/DEV/pasti";
let baseUrl = "https://xs681656.xsrv.jp/pasti";
axios.defaults.baseURL = baseUrl;

const state = {
  belong: null,
  chooseBelong: false,
  authData: null,
  token: null,
  branchSlug: null,
  branchTitle: null,
  breederName: null,
  isAuthenticated: false,
  capability: null,
}

const getters = {
  belong: (state) => state.belong,
  chooseBelong: (state) => state.chooseBelong,
  authData: (state) => state.authData,
  token: (state) => state.token,
  branchTitle: (state) => state.branchTitle,
  branchSlug: (state) => state.branchSlug,
  breederName: (state) => state.breederName,
  isAuthenticated: (state) => state.isAuthenticated,
  capability: (state) => state.capability,
}

const mutations = {
  belong(state, belong) {
    state.belong = belong;
  },
  chooseBelong(state, chooseBelong) {
    state.chooseBelong = chooseBelong;
  },
  authData(state, authData) {
    state.authData = authData;
  },
  token(state, token) {
    if(token){
      state.token = token;
      localStorage.setItem("token", token);
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`
    }else{
      state.token = null;
      localStorage.removeItem("token")
      axios.defaults.headers.common["Authorization"] = ""
    }
  },
  branchTitle(state, branchTitle) {
    state.branchTitle = branchTitle;
    localStorage.setItem("branchTitle", branchTitle);
  },
  branchSlug(state, branchSlug) {
    state.branchSlug = branchSlug;
    localStorage.setItem("branchSlug", branchSlug);
    if( branchSlug && branchSlug !== 'null' ){
      axios.defaults.baseURL = branchSlug === "pasti" ? baseUrl : baseUrl + `/${branchSlug}`
    }
  },
  breederName(state, breederName) {
    state.breederName = breederName;
    localStorage.setItem("breederName", breederName);
  },
  isAuthenticated(state, isAuthenticated) {
    state.isAuthenticated = isAuthenticated;
    localStorage.setItem("authenticated", true);
  },
  capability(state, capability) {
    state.capability = capability;
    localStorage.setItem("capability", capability);
  }
}

const actions = {

  //マスターログイン
  async login({commit,dispatch}, authData) {
    console.log("Start login to master branch");
    commit('authData', authData)

    try {
      axios.defaults.headers.common["Authorization"] = "";
      axios.defaults.baseURL = baseUrl;
      await axios
        .post("/wp-json/jwt-auth/v1/token", {
          username: authData.username,
          password: authData.password,
        })
        .then((response) => {
          console.log("Success master login");
          console.log(`Select ${response.data.title}`)
          commit('belong', response.data.belong)
          commit('token', response.data.token)
          commit('breederName', response.data.user_display_name)

          //複数所属の場合ブランチ選択
          if(response.data.belong.length > 1){
            console.log("multiple belonging")
            commit('chooseBelong', true)

          }else{

            commit('branchTitle', response.data.title)
            commit('branchSlug', response.data.slug)
            commit('capability', response.data.capability)
            dispatch('branchLogin')

          }
        })
    }
    catch (error) {
      const {
        status,
        statusText
      } = error.response;
      console.log(`Error! HTTP Status: ${status} ${statusText}`);
    }
  },

  //ブランチ選択
  selectBranch({commit,dispatch}, data) {
    commit('branchTitle', data.title)
    commit('branchSlug', data.slug)
    commit('capability', data.capability)
    dispatch('branchLogin')
  },

  //ブランチログイン
  async branchLogin({getters, commit}) {
    console.log("Attempt login to child branch");

    if (!getters.authData) {
      console.log("AuthData not found")
      return;
    }

    axios.defaults.headers.common["Authorization"] = "";

    try {
      await axios
        .post("/wp-json/jwt-auth/v1/token", {
          username: getters.authData.username,
          password: getters.authData.password,
        })
        .then((response) => {
          console.log('Success login child branch')
          commit("token", response.data.token)
          commit("isAuthenticated", true)
          router.push("/")
        })
    } catch (error) {
      const {
        status,
        statusText
      } = error.response;
      console.log(`Error! HTTP Status: ${status} ${statusText}`);
    }
  },

  //自動ログイン
  autoLogin({ commit, dispatch }) {

    const token = localStorage.getItem("token")
    if (!token) {
      console.log("token not found")
      return;
    }
    const branchSlug = localStorage.getItem("branchSlug")
    const branchTitle = localStorage.getItem("branchTitle")
    const breederName = localStorage.getItem("breederName")
    const capability = localStorage.getItem("capability")
    
    if( branchTitle !== 'null' ){
      console.log(`Currently selected ${branchTitle}`)
      commit("branchSlug", branchSlug)
      commit("branchTitle", branchTitle)
      commit("breederName", breederName)
      commit("capability", capability)
    }
    commit("token", token)
    dispatch("varidateToken")
  },

  //Token有効性確認
  async varidateToken({commit}) {
    const authenticated = localStorage.getItem("authenticated")
    if( authenticated ){
      commit('isAuthenticated', true)
    }else{
      await axios
      .post("/wp-json/jwt-auth/v1/token/validate")
      .then(() => {
        console.log("Token Validation success");
        commit('isAuthenticated', true)
      })
      .catch(() => {
        console.log("Token Validation error");
        commit('isAuthenticated', false)
      });
    }
  },

  //ログアウト
  logout({commit}) {
    commit('authData', null)
    commit('token', null)
    commit('branchSlug', null)
    commit('branchTitle', null)
    commit('breederName', null)
    commit('capability', null)
    commit('isAuthenticated', false)
    router.push("/login")
  },
}

const Authorize = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

export default Authorize;
