import axios from "axios"

const state = {
  logList: [],
  update: false
}

const getters = {
  logList: (state) => state.logList,
  update: (state) => state.update,
}

const mutations = {
  logList(state, logList) {
    state.logList = logList;
  },
  update(state, update) {
    state.update = update;
  },
}

const actions = {
  get({commit}, data) {
    if(!data.id) return
    axios
      .get(`/wp-json/pasti/v1/log/animal/${data.id}`)
      .then( response => {
        commit("logList", response.data)
        commit("update", false)
      })
  },
  delete({commit}, data) {
    if(!data.id) return
    axios
      .get(`/wp-json/pasti/v1/log/delete/${data.id}`)
      .then( () => {
        commit("update", false)
      })
  },
  deleteVaccinationLog({commit}, data) {
    if(!data.id) return
    axios
      .get(`/wp-json/pasti/v1/log/deleteVaccinationLog/${data.id}`)
      .then( response => {
        console.log(response)
        commit("update", false)
      })
  }
}

const AnimalLog = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

export default AnimalLog;
