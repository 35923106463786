<template>
  <aside class="veterinarian">
    <h1 id="titlelogo">
      <router-link
        class="navi-item"
        to='/'
        active-class="active"
      >
        <img width="36" height="36" src="@/assets/ico_footprint.svg">
        <span>PASTI<i>獣医師管理画面</i></span>
      </router-link>
    </h1>

    <ul>
      <template v-if="isAuthenticated">
        <li>
          <router-link
            class="navi-item"
            to="/breeders"
            active-class="active"
          >
            <img width="36" height="36" src="@/assets/ico_staff.svg">
            <span>ブリーダー</span>
          </router-link>
        </li>
        <li>
          <router-link
            class="navi-item"
            to="/mediate"
            active-class="active"
          >
            <img width="36" height="36" src="@/assets/ico_dog.svg">
            <span>譲渡リスト</span>
          </router-link>
        </li>
        <li>
          <span
            class="navi-item"
            @click='logout'
            active-class="active"
          >
            <img width="36" height="36" src="@/assets/ico_logout.svg">
            <span>ログアウト</span>
          </span>
        </li>
      </template>
      <template v-else>
        <li >
          <router-link
            class="navi-item"
            to="/login"
            active-class="active"
          >
            <img width="36" height="36" src="@/assets/ico_login.svg">
            <span>ログイン</span>
          </router-link>
        </li>
      </template>

    </ul>

  </aside>

</template>

<script>
export default {
  methods: {
    logout() {
      if(confirm('ログアウトしますか？')){
        this.$store.dispatch("Authorize/logout")
      }
    },
  },
  computed: {
    isAuthenticated() {
      return this.$store.getters['Authorize/isAuthenticated']
    }
  }
}
</script>

<style scoped>
.router-link-active:not(.navbar-brand) {
  font-weight: bold;
  color: #111!important;
}
</style>