<template>
  <b-modal
    title="スタッフ追加"
    id="add-staff-modal"
    hide-footer
    v-model="showModal"
    @shown="focusFirstElement"
    @show="resetModal"
  >
    <div
      v-if="errors.length"
      class='errors'
    >
      <div
        v-for="(error, index) in errors"
        :key='index'
      >{{ error }}</div>
    </div>

    <fieldset>
      <div class="form-floating mb-3">
        <input
          type="text"
          class="form-control"
          :class="{ 'is-invalid': isInvalid }"
          id="newStaffName"
          placeholder="お名前"
          v-model="newStaffName"
          ref="focusThis"
          @focus="this.isInvalid = false"
        >
        <label for="newStaffName">新しいスタッフのお名前</label>
      </div>

    </fieldset>

    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-secondary"
        data-bs-dismiss="modal"
        @click="cancel"
      >キャンセル</button>
      <button
        type="button"
        class="btn btn-primary"
        @click="submit"
      >追加する</button>
    </div>

  </b-modal>
</template>

<script>
export default {
  props: ["modal"],
  data() {
    return {
      newStaffName: null,
      showModal: false,
      processing: false,
      isInvalid: false,
      errors: [],
      error: false,
    }
  },
  mounted() {
    this.$store.watch(
      (state, getters) => getters['Staff/error'],
      (newValue) => {
         this.error = newValue
      }
    )
    this.$store.watch(
      (state, getters) => getters['Staff/processing'],
      (oldValue,newValue) => {
         if( oldValue === true && newValue === false ){
          this.processing = false
          this.$emit('hidden')
          this.resetModal()
          this.showModal = false
         }
      }
    )
  },
  methods: {
    focusFirstElement() {
      this.$refs.focusThis.focus()
    },
    resetModal() {
      this.newStaffName = null
      this.errors = []
    },
    validation() {
      this.errors = []
      this.isInvalid = false

      this.checking = true
      if (!this.newStaffName) {
        this.isInvalid = true
        this.errors.push("お名前を入力してください");
      }
      if (!this.errors.length) {
        return true;
      }
    },
    submit() {
      if(!this.validation())
        return
      
      this.processing = true
      this.$store.dispatch('Staff/addStaff', this.newStaffName)
    },
  },
  watch: {
    modal(val) {
      this.showModal = val
    },
    error(val) {
      if(val) {
        this.isInvalid = true
        this.errors.push("すでに存在するスタッフ名です");
      }
    }
  }

}
</script>