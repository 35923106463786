import axios from "axios"

const state = {
  animalList: [],
  maleList: [],
  femaleList: [],
  update: false,
  processing: false,
}

const getters = {
  animalList: (state) => state.animalList,
  maleList: (state) => state.maleList,
  femaleList: (state) => state.femaleList,
  update: (state) => state.update,
  processing: (state) => state.processing,
}

const mutations = {
  animalList(state, animalList) {
    state.animalList = animalList;
  },
  maleList(state, maleList) {
    state.maleList = maleList;
  },
  femaleList(state, femaleList) {
    state.femaleList = femaleList;
  },
  update(state, update) {
    state.update = update;
  },
  processing(state, processing) {
    state.processing = processing;
  }
}

const actions = {
  async loadAnimalList({commit}, breederId) {
    try {
      await axios
        .get(`/wp-json/pasti/v1/breeder/${breederId}/animals`)
        .then((response) => {
          console.log('Success load animal list')
          commit('animalList',response.data)

          let maleList = response.data.filter( animal => animal.sex === 'male' )
          let femaleList = response.data.filter( animal => animal.sex === 'female' )
          commit('maleList',maleList)
          commit('femaleList',femaleList)
        })
    }
    catch (error) {
      const {
        status,
        statusText
      } = error.response;
      console.log(`Error! HTTP Status: ${status} ${statusText}`)
    }
  },
  
}

const Doctor = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

export default Doctor;
