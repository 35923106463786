<template>

  <SideNaviMaster v-if="capability==='admin'||capability==='group-master'" />
  <SideNavi v-else />
  
  <div id="content">
    <router-view />
    <div class="copyright">Powerd by <span>Pasti Animal Welfare</span></div>
    <div class="loader"></div>
  </div>

</template>

<script>
import SideNavi from "@/components/SideNavi.vue"
import SideNaviMaster from "@/components/Doctor/SideNavi.vue"
export default {
  components: {
    SideNavi,
    SideNaviMaster
  },
  data() {
    return {
      loading: true,
      capability: ""
    }
  },
  mounted() {

    this.capability = this.$store.getters['Authorize/capability']
    this.$store.watch(
      (state, getters) => getters['Authorize/capability'],
      (newValue) => {
        this.capability = newValue
      }
    )

  },
  methods: {
    loaded() {
      this.loading = false
    }
  }
}
</script>
