import axios from "axios"
import speciesList from "@/assets/speciesList.js"

const state = {
  animalList: [],
  maleList: [],
  femaleList: [],
  speciesList: [],
  update: false,
  processing: false,
}

const getters = {
  animalList: (state) => state.animalList,
  maleList: (state) => state.maleList,
  femaleList: (state) => state.femaleList,
  speciesList: (state) => state.speciesList,
  update: (state) => state.update,
  processing: (state) => state.processing,
}

const mutations = {
  animalList(state, animalList) {
    state.animalList = animalList;
  },
  maleList(state, maleList) {
    state.maleList = maleList;
  },
  femaleList(state, femaleList) {
    state.femaleList = femaleList;
  },
  speciesList(state, speciesList) {
    state.speciesList = speciesList;
  },
  update(state, update) {
    state.update = update;
  },
  processing(state, processing) {
    state.processing = processing;
  }
}

const actions = {
  async loadAnimalList({commit}) {
    try {
      await axios
        .get("/wp-json/pasti/v1/animal")
        .then((response) => {
          console.log('Success load animal list')
          commit('animalList',response.data)

          let maleList = response.data.filter( animal => animal.sex === 'male' )
          let femaleList = response.data.filter( animal => animal.sex === 'female' )
          commit('maleList',maleList)
          commit('femaleList',femaleList)
        })
    }
    catch (error) {
      const {
        status,
        statusText
      } = error.response;
      console.log(`Error! HTTP Status: ${status} ${statusText}`)
    }
  },
  pushIntoAnimalList({getters,commit},data) {
    let newAnimal = {
      id: data.id,
      number: data.data.number,
      name: data.data.name,
      genus: data.data.genus,
      species: data.data.species,
      sex: data.data.sex,
      age: data.age,
      numBirth: data.data.numBirth,
      thumbUrl: data.data.imgUrl,
    }
    let newList = getters['animalList']
    newList.unshift(newAnimal)
    commit('animalList', newList)
  },
  removeFromAnimalList({getters,commit}, targetId) {
    let newList = getters['animalList']
    newList = newList.find( ({ id }) => id !== targetId )
    commit('animalList', newList)
  },
  loadMatchSpecies({commit}, data) {
    const species = speciesList[data.genus]
    commit('speciesList', [])
    let tempList = [];
    if(data.str.length){
      species.forEach((row) => {
        if( row.name.indexOf(data.str) !== -1 ){
          tempList.push(row)
        }
      })
      commit('speciesList', tempList)
    }
  },
  
}

const Kennel = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

export default Kennel;
