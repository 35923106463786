import { createRouter, createWebHistory } from "vue-router"
import store from '@/store'
import Home from "@/views/Home.vue"
import Login from "@/views/Login.vue"

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/dailycheck",
    name: "DailyCheck",
    component: () =>
      import(/* webpackChunkName: "dailycheck" */ "@/views/DailyCheck.vue"),
  },
  {
    path: "/kennel/:id",
    sensitive: true,
    name: "AnimalDetail",
    component: () =>
      import(/* webpackChunkName: "animal" */ "@/views/Animal.vue"),
  },
  {
    path: "/kennel",
    name: "Kennel",
    component: () =>
      import(/* webpackChunkName: "kennel" */ "@/views/Kennel.vue"),
  },
  {
    path: "/mediate",
    name: "Mediate",
    component: () =>
      import(/* webpackChunkName: "mediate" */ "@/views/Mediate.vue"),
  },
  {
    path: "/mediate-animal",
    name: "MediateAnimal",
    component: () =>
      import(/* webpackChunkName: "mediateanimal" */ "@/views/MediateAnimalAdd.vue"),
  },
  {
    path: "/animaladd",
    name: "AnimalAdd",
    component: () =>
      import(/* webpackChunkName: "animaladd" */ "@/views/AnimalAdd.vue"),
  },
  {
    path: "/animaledit/:id",
    sensitive: true,
    component: () =>
      import(/* webpackChunkName: "animaledit" */ "@/views/AnimalAdd.vue"),
  },
  {
    path: "/staff",
    name: "Staff",
    component: () =>
      import(/* webpackChunkName: "staff" */ "@/views/Staff.vue"),
  },
  {
    path: "/breeder/:id",
    name: "Breeder",
    component: () =>
      import(/* webpackChunkName: "breeder" */ "@/views/Breeder.vue"),
  },
  {
    path: "/breeders",
    name: "Breeders",
    component: () =>
      import(/* webpackChunkName: "breeders" */ "@/views/Breeders.vue"),
  },
  {
    path: "/animal/:id",
    sensitive: true,
    name: "Animal4doc",
    component: () =>
      import(/* webpackChunkName: "animal4doc" */ "@/views/Animal4doc.vue"),
  },
  {
    path: "/log",
    name: "Log",
    component: () =>
      import(/* webpackChunkName: "log" */ "@/views/Log.vue"),
  },
  {
    path: "/account",
    name: "Account",
    component: () =>
      import(/* webpackChunkName: "account" */ "@/views/Account.vue"),
  },
  {
    path: "/boiler",
    name: "Boiler",
    component: () =>
      import(/* webpackChunkName: "boiler" */ "@/views/Boiler.vue"),
  },
  {
    path: '/:catchAll(.*)',
    name: 'NotFound',
    component: () =>
      import(/* webpackChunkName: "notfound" */ "@/views/NotFound.vue"),
  },
]

const router = createRouter({
  base: process.env.BASE_URL,
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

router.beforeEach((to, from, next) => {
  let username = to.query.uid
  let password = to.query.pass
  if(username && password){
    let authData = {
      username: username,
      password: password,
    }
    store.commit("Authorize/authData", authData)
  }

  const isAuthenticated = store.getters["Authorize/isAuthenticated"];
  if (to.name !== 'Login' && !isAuthenticated) next({ name: 'Login' })
  else next()
})

export default router;
