<template>

  <RangeSelect
    @updateList="updateList"
  />

  <table class="table table-striped">
    <tbody>
      <tr
        v-for="row in logList"
        :key="row.id"
      >
        <td class="status">{{row.status}}</td>
        <td class="staff">{{row.staff}}</td>
        <td class="datetime">{{row.datetime}}</td>
      </tr>
    </tbody>
  </table>

</template>

<script>
import axios from "axios";
import RangeSelect from "@/components/Staff/RangeSelect"

export default {
  components: {
    RangeSelect
  },
  data() {
    return {
      logList: []
    }
  },
  mounted() {
    this.loadAttendanceLog()

    this.$store.watch(
      (state, getters) => getters['Staff/update'],
      (newValue) => {
        if(newValue) {
          this.loadAttendanceLog()
          this.$store.commit("Staff/update", false)
        }
      }
    )
  },
  methods: {
    loadAttendanceLog() {
      axios
        .get("/wp-json/pasti/v1/log/attendance/today")
        .then( response => {
          this.logList = response.data
        })
        .catch( error => {
          console.log(error);
        })
    },
    updateList(data) {
      this.logList = data
    }
  }
}
</script>