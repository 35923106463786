<template>

  <header class="p-3 bg-primary text-white">
    <div class="d-flex align-items-center">
      <img width="40" height="40" src="@/assets/ico_footprint.svg">
      <div class="lh-1 mx-2">
        <h1 class="h6 mb-0 text-white lh-1 fw-bold">ダッシュボード</h1>
      </div>
    </div>
  </header>

  <main class="p-3">
    
    <MasterDashboard
      v-if="capability==='admin'||capability==='group-master'"
    />
    <Dashboard
      v-else
    />

  </main>
</template>

<script>
import Dashboard from "@/components/Dashboard"
import MasterDashboard from "@/components/Doctor/Dashboard"

export default {
  name: "Home",
  components: {
    Dashboard,
    MasterDashboard,
  },
  computed: {
    capability() {
      return this.$store.getters['Authorize/capability']
    }
  }
}
</script>
