<template>
  <li @click="flip">
    <div class="panel" :class="{flip: status}">
      <div class="front">
        <div>
          <div>{{staff.name}}</div>
          <div v-if="datetime">{{datetime}}</div>
        </div>
      </div>
      <div class="back">
        <div>
          {{staff.name}}
          <div>出勤</div>
          <div v-if="datetime">{{datetime}}</div>
        </div>
      </div>
    </div>
  </li>
</template>

<script>
export default {
  props: ["staff"],
  data() {
    return {
      status: false,
      datetime: null
    }
  },
  mounted() {
    this.status = this.$props.staff.status ? true : false
  },
  methods: {
    flip() {
      this.status = !this.status
      this.$store.dispatch("Staff/attendance", {
        id: this.$props.staff.id,
        status: this.status
      })
      let datetime = new Date()
      this.datetime = ('0' + datetime.getHours()).slice(-2) + ':' + ('0' + datetime.getMinutes()).slice(-2)
    }
  },
}
</script>