<template>

  <h2 class="sectiontitle">{{year}}年{{month}}月{{dateNum}}日({{dow}})のデイリーチェック実施状況</h2>

  <table class="table mb-3 check-summary">
    <thead>
      <tr>
        <th class="breedername">ブリーダー</th>
        <th>清掃</th>
        <th>消毒</th>
        <th>保守</th>
        <th>動物状態</th>
        <th>動物頭数</th>
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="breeder in breederList"
        :key="breeder.id"
      >
        <td class="breedername" @click="showDetail(breeder.id)">
          {{breeder.name}}
        </td>
        <td>
          <span v-if="cleaning(breeder.checklist)==='1'" class='badge bg-primary'>OK</span>
          <span v-else-if="cleaning(breeder.checklist)==='0'" class='badge bg-danger'>NG</span>
          <span v-else>--</span>
        </td>
        <td>
          <span v-if="sterilize(breeder.checklist)==='1'" class='badge bg-primary'>OK</span>
          <span v-else-if="sterilize(breeder.checklist)==='0'" class='badge bg-danger'>NG</span>
          <span v-else>--</span>
        </td>
        <td>
          <span v-if="maintain(breeder.checklist)==='1'" class='badge bg-primary'>OK</span>
          <span v-else-if="maintain(breeder.checklist)==='0'" class='badge bg-danger'>NG</span>
          <span v-else>--</span>
        </td>
        <td>
          <span v-if="animalstatus(breeder.checklist)==='1'" class='badge bg-primary'>OK</span>
          <span v-else-if="animalstatus(breeder.checklist)==='0'" class='badge bg-danger'>NG</span>
          <span v-else>--</span>
        </td>
        <td>
          <span v-if="animalcount(breeder.checklist)==='1'" class='badge bg-primary'>OK</span>
          <span v-else-if="animalcount(breeder.checklist)==='0'" class='badge bg-danger'>NG</span>
          <span v-else>--</span>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import axios from "axios"
export default {
  data() {
    return {
      date: "",
      dateNum: "",
      breederList: []
    }
  },
  created() {
    this.date = new Date()
    this.dateNum = this.date.getDate()
    this.breederList = this.getBreederList()
  },
  methods: {
    getBreederList() {
      axios
        .get("/wp-json/pasti/v1/breeders/today")
        .then( response => {
          console.log(response.data)
          this.breederList = response.data
        })
        .catch( error => {
          console.log(error);
        })
    },
    showDetail(id) {
      this.$router.push('/breeder/' + id)
    }
  },
  computed: {
    year() {
      return this.date.getFullYear()
    },
    month() {
      return this.date.getMonth() + 1
    },
    dow() {
      const dow = this.date.getDay()
      const array = ['日','月','火','水','木','金','土']
      return array[dow] 
    },
    dateStr() {
      let date = new Date()
      date = date.toJSON()
      date = date.split('T')
      return date[0]
    },
    cleaning() {
      return function(checklist) {
        let res = false
        if(checklist){
          if('facility' in checklist){
            if('cleaning' in checklist.facility){
              res = checklist.facility.cleaning
            }
          }
        }
        return res
      }
    },
    sterilize() {
      return function(checklist) {
        let res = false
        if(checklist){
          if('facility' in checklist){
            if('sterilize' in checklist.facility){
              res = checklist.facility.sterilize
            }
          }
        }
        return res
      }
    },
    maintain() {
      return function(checklist) {
        let res = false
        if(checklist){
          if('facility' in checklist){
            if('maintain' in checklist.facility){
              res = checklist.facility.maintain
            }
          }
        }
        return res
      }
    },
    animalstatus() {
      return function(checklist) {
        let res = false
        if(checklist){
          if('animalstatus' in checklist){
            res = checklist.animalstatus.status
          }
        }
        return res
      }
    },
    animalcount() {
      return function(checklist) {
        let res = false
        if(checklist){
          if('animalcount' in checklist){
            res = checklist.animalcount.status
          }
        }
        return res
      }
    },
  },
}
</script>

<style scoped>
  td:not(.breedername),
  th:not(.breedername) {
    width: 10%;
    text-align: center;
  }
  .breedername {
    width: 50%;
  }
  td.breedername {
    color: var(--bs-primary);
    text-decoration: underline;
  }
</style>