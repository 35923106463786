const speciesList = {
  dog: [
    { id: "001", name: "アイリッシュ・ウルフハウンド" },
    { id: "002", name: "アイリッシュ・セッター" },
    { id: "003", name: "秋田犬" },
    { id: "004", name: "アフガン・ハウンド" },
    { id: "005", name: "アメリカン・コッカー・スパニエル" },
    { id: "006", name: "アラスカン・マラミュート" },
    { id: "007", name: "イタリアン・グレーハウンド" },
    { id: "008", name: "イングリッシュ・コッカー・スパニエル" },
    { id: "009", name: "イングリッシュ・スプリンガー・スパニエル" },
    { id: "010", name: "ウィペット" },
    { id: "011", name: "ウエスト・ハイランド・ホワイト・テリア" },
    { id: "012", name: "ウェルシュ・コーギー" },
    { id: "013", name: "ウェルシュ・テリア" },
    { id: "014", name: "エアデール・テリア" },
    { id: "015", name: "エスキモー犬" },
    { id: "016", name: "オーストラリアン・キャトル・ドッグ" },
    { id: "017", name: "オールド・イングリッシュ・シープドッグ" },
    { id: "018", name: "甲斐犬" },
    { id: "019", name: "キースホンド" },
    { id: "020", name: "紀州犬" },
    { id: "021", name: "キャバリア・キング・チャールズ・スパニエル" },
    { id: "022", name: "グレート・デーン" },
    { id: "023", name: "グレート・ピレニーズ" },
    { id: "024", name: "ケアーン・テリア" },
    { id: "025", name: "ケリーブルー・テリア" },
    { id: "026", name: "ゴールデン・レトリーバー" },
    { id: "027", name: "コリー" },
    { id: "028", name: "サモエド" },
    { id: "029", name: "サルーキ" },
    { id: "030", name: "シー・ズー" },
    { id: "031", name: "シェットランド・シープドッグ" },
    { id: "032", name: "シェパード" },
    { id: "033", name: "四国犬" },
    { id: "034", name: "柴犬" },
    { id: "035", name: "シベリアン・ハスキー" },
    { id: "036", name: "シャー・ペイ" },
    { id: "037", name: "ジャーマン・シェパード・ドッグ" },
    { id: "038", name: "ジャック・ラッセル・テリア" },
    { id: "039", name: "シュナウザー（ジャイアント）" },
    { id: "040", name: "シルキー・テリア" },
    { id: "041", name: "スコティッシュ・テリア" },
    { id: "042", name: "スピッツ" },
    { id: "043", name: "スプリンガー・スパニエル" },
    { id: "044", name: "セッター" },
    { id: "045", name: "セント・バーナード" },
    { id: "046", name: "ダックスフンド（スタンダード）" },
    { id: "047", name: "ダルメシアン" },
    { id: "048", name: "チベタン・テリア" },
    { id: "049", name: "チャウ・チャウ" },
    { id: "050", name: "チワワ" },
    { id: "051", name: "狆（チン）" },
    { id: "052", name: "テリア" },
    { id: "053", name: "ドーベルマン" },
    { id: "054", name: "トイ・プードル" },
    { id: "055", name: "土佐犬" },
    { id: "056", name: "日本テリア" },
    { id: "057", name: "ニューファンドランド" },
    { id: "058", name: "ノルウェジアン・エルクハウンド" },
    { id: "059", name: "バーニーズ・マウンテン・ドッグ" },
    { id: "060", name: "パグ" },
    { id: "061", name: "バセット・ハウンド" },
    { id: "062", name: "バセンジー" },
    { id: "063", name: "パピヨン" },
    { id: "064", name: "ビーグル" },
    { id: "065", name: "ビアデッド・コリー" },
    { id: "066", name: "ビション・フリーゼ" },
    { id: "067", name: "プードル（スタンダード）" },
    { id: "068", name: "ブービエ・デ・フランドル" },
    { id: "069", name: "フォックス・テリア" },
    { id: "070", name: "フラットコーテッド・レトリーバー" },
    { id: "071", name: "ブリュッセル・グリフォン" },
    { id: "072", name: "ブルタニー・スパニエル" },
    { id: "073", name: "ブル・テリア" },
    { id: "074", name: "ブルドッグ" },
    { id: "075", name: "ブル・マスティフ" },
    { id: "076", name: "フレンチ・ブルドッグ" },
    { id: "077", name: "プロット・ハウンド" },
    { id: "078", name: "ペキニーズ" },
    { id: "079", name: "ベドリントン・テリア" },
    { id: "080", name: "ベルジアン・タービュレン" },
    { id: "081", name: "ボーダー・コリー" },
    { id: "082", name: "ホイペット" },
    { id: "083", name: "ポインター" },
    { id: "084", name: "ボクサー" },
    { id: "085", name: "ボストン・テリア" },
    { id: "086", name: "北海道犬" },
    { id: "087", name: "ポメラニアン" },
    { id: "088", name: "ボルゾイ" },
    { id: "089", name: "マスティフ" },
    { id: "090", name: "マルチーズ" },
    { id: "091", name: "マレンマ（マレンマシープドッグ）" },
    { id: "092", name: "マンチェスター・テリア" },
    { id: "093", name: "三河犬" },
    { id: "094", name: "ミニチュア・シュナウザー" },
    { id: "095", name: "ミニチュア・ダックスフンド" },
    { id: "096", name: "ミニチュア・ピンシャー" },
    { id: "097", name: "ミニチュア・プードル" },
    { id: "098", name: "ミニチュア・ブル・テリア" },
    { id: "099", name: "ヨークシャー・テリア" },
    { id: "100", name: "ラサ・アプソ" },
    { id: "101", name: "ラブラドール・レトリーバー" },
    { id: "102", name: "レークランド・テリア" },
    { id: "103", name: "ロットワイラー" },
    { id: "104", name: "ワイマラナー" },
    { id: "300", name: "雑種" },
    { id: "999", name: "その他" },
  ],
  cat: [
    { id: "001", name: "アビシニアン" },
    { id: "002", name: "アメリカンカール" },
    { id: "003", name: "アメリカンショートヘアー" },
    { id: "004", name: "アメリカンワイヤーヘアー" },
    { id: "005", name: "エキゾチック" },
    { id: "006", name: "エジプシャンマウ" },
    { id: "007", name: "オシキャット" },
    { id: "008", name: "オリエンタル" },
    { id: "009", name: "カラーポイントショートヘアー" },
    { id: "010", name: "コーニッシュレックス" },
    { id: "011", name: "コラット" },
    { id: "012", name: "サイアミーズ" },
    { id: "013", name: "サイベリアン" },
    { id: "014", name: "ジャパニーズ" },
    { id: "015", name: "ジャパニーズボブテール" },
    { id: "016", name: "シャム" },
    { id: "017", name: "シャルトリュー" },
    { id: "018", name: "シンガプーラ" },
    { id: "019", name: "スキフトーイボブテール" },
    { id: "020", name: "スコティッシュフォールド" },
    { id: "021", name: "スフィンクス" },
    { id: "022", name: "セルカークレックス" },
    { id: "023", name: "ソマリ" },
    { id: "024", name: "ターキッシュ・アンゴラ（トルコアンゴラ）" },
    { id: "025", name: "ターキッシュ・ヴァン（トルコネコ）" },
    { id: "026", name: "デボンレックス" },
    { id: "027", name: "トンキニーズ" },
    { id: "028", name: "日本猫" },
    { id: "029", name: "ノルウェージャンフォレストキャット" },
    { id: "030", name: "バーマン" },
    { id: "031", name: "バーミーズ" },
    { id: "032", name: "ハバナブラウン" },
    { id: "033", name: "バリニーズ" },
    { id: "034", name: "ヒマラヤン" },
    { id: "035", name: "ブリティッシュショートヘアー" },
    { id: "036", name: "ペルシャ" },
    { id: "037", name: "ベンガル" },
    { id: "038", name: "ボンベイ" },
    { id: "039", name: "マンクス" },
    { id: "040", name: "マンチカン" },
    { id: "041", name: "メインクーン" },
    { id: "042", name: "ヨーロピアンバーミーズ" },
    { id: "043", name: "ラグドール" },
    { id: "044", name: "ロシアンブルー" },
    { id: "100", name: "雑種" },
    { id: "999", name: "その他" },
  ],
}

export default speciesList;
