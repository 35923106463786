import axios from "axios"
import router from "../router";
import speciesList from "@/assets/speciesList.js"

const state = {
  container: null,
  speciesList: [],
  processing: false,
  reload: false,
}

const getters = {
  container: (state) => state.container,
  speciesList: (state) => state.speciesList,
  processing: (state) => state.processing,
  reload: (state) => state.reload,
}

const mutations = {
  container(state, container) {
    state.container = container;
  },
  speciesList(state, speciesList) {
    state.speciesList = speciesList;
  },
  processing(state, processing) {
    state.processing = processing;
  },
  reload(state, reload) {
    state.reload = reload;
  },
}

const actions = {
  add({commit}, data) {
    commit('processing', true)

    try {
      let fd = new FormData()
      fd.append("pic", data.uploadFile)
      fd.append("animaldata", JSON.stringify(data))
      axios
        .post("/wp-json/pasti/v1/mediate-animal", fd)
        .then((response) => {
          console.log(response.data)
          commit('processing', false)
          commit('reload', true)
          router.push('/mediate')
        })
    }
    catch (error) {
      const {
        status,
        statusText
      } = error.response;
      console.log(`Error! HTTP Status: ${status} ${statusText}`)
      commit('processing', false)
    }
  },
  // update({commit}, data) {
  //   commit('processing', true)
  //   try {
  //     let fd = new FormData()
  //     fd.append("pic", data.uploadFile)
  //     fd.append("animaldata", JSON.stringify(data))
  //       axios
  //       .post(`/wp-json/pasti/v1/animal/${data.wp_post_id}/update`, fd)
  //       .then((response) => {
  //         console.log(response)
  //         commit('processing', false)
  //         router.push('/kennel')
  //       })
  //   }
  //   catch (error) {
  //     const {
  //       status,
  //       statusText
  //     } = error.response;
  //     console.log(`Error! HTTP Status: ${status} ${statusText}`)
  //     commit('processing', false)
  //   }
  // },
  // delete({commit}, id) {
  //   commit('processing', true)
  //   axios
  //     .post("/wp-json/pasti/v1/animal/delete", {
  //       id: id
  //     })
  //     .then((response) => {
  //       console.log(response)
  //       commit('processing', false)
  //       router.push('/kennel')
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // },
  reset({commit}) {
    commit('container', null)
  },
  getInfo({commit}, id) {
    if(!id) return

    console.log("try get detail info")
    axios
    .get(`/wp-json/pasti/v1/animal/${id}`)
    .then((response) => {

      //誕生日
      let birthday = response.data.birthday;
      birthday = birthday.split("T")
      response.data.birthday = birthday[0]

      //入手日
      let obtainDate = response.data.ownership_date;
      obtainDate = obtainDate.split("T")
      response.data.ownership_date = obtainDate[0]
      commit('container', response.data)
    })
  },
  addLog(context, data) {
    axios
    .post(`/wp-json/pasti/v1/animal/${data.id}/log`, {
      genre: data.genre,
      str: data.str
    })
    .then((response) => {
      console.log(response)
    })
  },
  loadMatchSpecies({getters, commit}, str) {
    if(str.length){
      const species = speciesList[getters["genus"]]
      commit('speciesList', [])
      let tempList = []
      species.forEach((row) => {
        if( row.name.indexOf(str) !== -1 ){
          tempList.push(row)
        }
      })
      commit('speciesList', tempList)
    }
  },
}

const Mediate = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

export default Mediate;
