<template>
  <div class="text-center">
    <div class="form-signin">

      <form v-if="!chooseBelong">
        <h1 class="h3 mb-3 fw-normal">PASTI</h1>

        <div class='userselect'>
          <select
            class="form-select"
            @change="changeUser"
          >
            <option value="">テスト用ブリーダー選択</option>
            <option
              v-for="(user, index) in users"
              :key='index'
              :value="user.name"
            >{{user.dispname}}</option>
          </select>
        </div>


        <div class="form-floating">
          <input
            type="text"
            class="form-control"
            id="floatingInput"
            placeholder="pasti user"
            v-model="authData.username"
          >
          <label for="floatingInput">ユーザー名</label>
        </div>
        <div class="form-floating">
          <input
            type="password"
            class="form-control"
            id="floatingPassword"
            placeholder="パスワード"
            v-model="authData.password"
          >
          <label for="floatingPassword">パスワード</label>
        </div>

        <button
          class="w-100 btn btn-lg btn-primary"
          type="submit"
          @click.prevent="login"
        >ログイン</button>
        <p class="mt-5 mb-3 text-muted">&copy;PASTI 2022</p>
      </form>

      <form v-if="chooseBelong">
        <h1 class="h3 mb-3 fw-normal">ブランチ選択</h1>
        <div class='userselect'>
          <select
            class="form-select"
            @change="chooseBranch"
          >
            <option value="">選択してください</option>
            <option
              v-for="(branch, index) in belong"
              :key='index'
              :value="branch.slug"
            >{{branch.title}}</option>
          </select>
        </div>
      </form>

    </div>
  </div>
</template>

<script>
  export default {
    name: "Login",
    data() {
      return {
        authData: {
          username: "",
          password: "",
        },
        users: [
          {
            dispname: "さくらももこ",
            name: "sakuramomoko",
            password: "&XbnIB%z3GnTz8nEUrVNJ)rd"
          },
          {
            dispname: "穂波たまえ",
            name: "honamitamae",
            password: "q#%9zi2XhrTJ(znhq5O2AGK!"
          },
          {
            dispname: "丸尾末男",
            name: "maruosueo",
            password: "d%xrmE6D2DudbtZA4rfcdyW3"
          }
        ],
        belong: null,
        chooseBelong: false,
      }
    },
    mounted() {

      this.$store.watch(
        (state, getters) => getters['Authorize/chooseBelong'],
        (newValue) => {
          this.chooseBelong = newValue
          this.belong = this.$store.getters['Authorize/belong']
        }
      )

      if(this.$store.getters['Authorize/authData']){
        this.authData = this.$store.getters['Authorize/authData']
      }

    },
    methods: {
      login() {
        this.$store.dispatch("Authorize/login", this.authData);
      },
      changeUser(data) {
        if(data.target.value === '') return

        let user = this.users.find( ({name}) => name === data.target.value )
        this.authData.username = user.name
        this.authData.password = user.password
      },
      chooseBranch(data){
        let branch = this.belong.find( row => row.slug === data.target.value )
        this.$store.dispatch("Authorize/selectBranch", {
          title: branch.title,
          slug: branch.slug,
          capability: branch.capability
        })
      }
    },
  }
</script>

<style scoped>
.userselect {
  margin-bottom: 10px;
}
.text-center {
  height: 100%;
}
.text-center {
  display: flex;
  align-items: center;
  padding-top: 40px;
  padding-bottom: 40px;
}
.form-signin {
  width: 450px;
  max-width: 95%;
  padding: 30px;
  margin: auto;
  background-color: #f5f5f5;
  border: 1px solid #ddd;
}
.form-signin .checkbox {
  font-weight: 400;
}
.form-signin .form-floating:focus-within {
  z-index: 2;
}
.form-signin input[type="text"] {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.form-signin input[type="password"] {
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
</style>