import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import BootstrapVue3 from 'bootstrap-vue-3'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue-3/dist/bootstrap-vue-3.css'
import '@/assets/style.css'
import '@/assets/ui.css'


store.dispatch("Authorize/autoLogin").then(() => {
  createApp(App)
  .use(store)
  .use(router)
  .use(BootstrapVue3)
  .mount("#app");
});


