<template>
  <nav id="range-select">
    <label>日付</label>
    <div>
      <input
        type="date"
        class="form-control"
        :max="today"
        @change="setFrom"
      >
    </div>
    <span>〜</span>
    <div>
      <input
        type="date"
        class="form-control"
        :max="today"
        @change="setTo"
      >
    </div>
  </nav>
</template>

<script>
import axios from "axios";
export default {
  emits: ["updateList"],
  data() {
    return {
      from: "",
      to: ""
    }
  },
  methods: {
    setFrom(date) {
      this.from = date.target.value
      this.dateQuery()
    },
    setTo(date) {
      this.to = date.target.value
      this.dateQuery()
    },
    dateQuery(){
      axios
        .post("/wp-json/pasti/v1/log/attendance/range", {
          from: this.from,
          to: this.to
        })
        .then( response => {
          console.log(response)
          this.$emit("updateList", response.data)
        })
        .catch( error => {
          console.log(error);
        })
    }
  },
  computed: {
    today() {
      let today = new Date()
      today = "" + today.getFullYear() + "-" + ('0' + (today.getMonth()+1)).slice(-2) + "-" + ('0' + today.getDate()).slice(-2)
      console.log(today)
      return today
    }
  }
}
</script>
